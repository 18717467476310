import { Box, Divider, Typography } from '@mui/material';
import React, {Fragment} from 'react';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import Link from 'next/link';
import {OBI_Broker} from "../../../types/object";
import executeGrecaptcha from "../../../utils/executeGrecaptcha";

const ContactFormXS = ({ baseId, broker, message, seller, tomt, status }) => {
  const [contactRadio, setContactRadio] = React.useState('1');
  const [contactMessage, setContactMessage] = React.useState('');
  const [errMessage, setErrMessage] = React.useState('');
  const [errDetailsMessage, setErrDetailsMessage] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [mobilePhoneNumberOrEmail, setMobilePhoneNumberOrEmail] =
    React.useState('');
  React.useEffect(() => {
    setContactMessage(message);
  }, [message]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactRadio((event.target as HTMLInputElement).value);
  };

  const handleSubmit2 = React.useCallback(
    async (recipient) => {
      const userEmail = email.trim()
      const userPhone = phone.trim()
      const userName = name.trim()
      const userContactMessage = contactMessage.trim()
      if (!userName) {
        setErrMessage('Ange ett namn.');
        return;
      }
      if (!userPhone && !userEmail) {
        setErrMessage('Ange e-postadress eller telefonnr');
        return;
      }
      if (email && !isEmail(userEmail)) {
        setErrMessage('Ange en korrekt e-postadress.');
        return;
      }
      if (!userContactMessage) {
        setErrMessage('Ange ett meddelande.');
        return;
      }
      try {
        executeGrecaptcha("Contact", async (token: string) => {
          const contactSellerOrBrokerResult = await axios.post(
            `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/object/contactSellerOrBroker`,
            {
              captcha: token,
              name: userName,
              email: userEmail,
              phone: userPhone,
              message: userContactMessage,
              recipient,
              baseId: baseId,
            }
          );
          if (contactSellerOrBrokerResult && contactSellerOrBrokerResult.data.success === true) {
            setErrMessage('Meddelandet har skickats.');
          }
        })
      } catch (error) {
        setErrMessage('Okänt fel. Vänligen kontakta kundtjänst.');
      }
    },
    [baseId, contactMessage, contactRadio, email, name, phone]
  );
  const handleSubmitDetails = React.useCallback(async () => {
    if (mobilePhoneNumberOrEmail === '') {
      setErrDetailsMessage('Ange ett korrekt telefonnr.');
      return;
    }
    try {
      executeGrecaptcha("Contact", async (token: string) => {
        const recaptchaData = await axios.post(
          `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/object/sendContactDetails`,
          {
            captcha: token,
            mobilePhoneNumberOrEmail: mobilePhoneNumberOrEmail,
            baseId: baseId,
          }
        );
        if (recaptchaData && recaptchaData.data.success === true) {
          setErrDetailsMessage('Meddelandet har skickats.');
        }
      })
    } catch (error) {
      setErrDetailsMessage('Okänt fel. Vänligen kontakta kundtjänst.');
    }
  }, [baseId, mobilePhoneNumberOrEmail]);

  return (
    <Box
      p={3}
      sx={{
        marginTop: '50px',
      }}
    >
      <Typography
        sx={{
          fontFamily: 'Roboto-Regular',
          fontSize: '18px',
          textTransform: 'uppercase',
        }}
      >
        Kontakt
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {status !== 9 && (
          <>
            {broker ? (
              <>
                <Typography
                  sx={{
                    fontFamily: 'Roboto-Bold',
                    fontSize: '15px',
                    marginTop: '20px',
                    // textTransform: 'uppercase',
                  }}
                >
                  {`Hos Privatmäklaren sköter ägaren visningarna och svarar på
              detaljfrågor om objektet.`}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '15px',
                    // textTransform: 'uppercase',
                  }}
                >
                  {`Ansvarig mäklare hanterar övriga frågor kring regler, juridik budgivning mm.`}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: 'Roboto-Bold',
                    fontSize: '15px',
                    marginTop: '20px',
                    // textTransform: 'uppercase',
                  }}
                >
                  Detta objekt säljs på offentlig auktion. Ansvarig god man använder vår Annonstjänst.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '15px',
                    // textTransform: 'uppercase',
                  }}
                >
                  {`Kontakta annonsören ${seller.firstName} ${seller.lastName}`}
                </Typography>
              </>
            )}

            {errMessage === 'Meddelandet har skickats.' && (
              <Typography
                sx={{
                  color:
                    errMessage === 'Meddelandet har skickats.'
                      ? 'green'
                      : 'red',
                }}
              >
                {errMessage}
              </Typography>
            )}

            {errMessage !== 'Meddelandet har skickats.' && (
              <>
                {broker && (
                  <FormControl fullWidth component="fieldset" sx={{ mt: '20px' }}>
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: '16px',
                        fontFamily: 'Roboto-Bold',
                        textTransform: 'uppercase',
                      }}
                    >{`Jag har frågor om:`}</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={contactRadio}
                      onChange={handleChange}
                      sx={{ fontFamily: 'Roboto-Regular', fontSize: '15px' }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label={(tomt ? 'Tomten' : 'Bostaden') + ' och visning.'}
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Lato',
                          fontSize: '14px',
                          ml: '32px',
                        }}
                      >
                        {`Säljaren kan mest om ${
                          tomt ? 'tomten' : 'bostaden'
                        } och sköter visningen.`}
                      </Typography>
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Övriga frågor."
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Lato',
                          fontSize: '14px',
                          ml: '32px',
                        }}
                      >
                        {`Mäklaren hanterar frågor om budgivning, regler, jurdik mm.`}
                      </Typography>
                    </RadioGroup>
                  </FormControl>
                )}

                <>
                  <TextField
                    label="Ditt namn"
                    name={"name"}
                    variant="outlined"
                    sx={{ mt: '15px' }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    label="Din e-post"
                    name={"email"}
                    variant="outlined"
                    sx={{ mt: '15px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    label="Ditt telefonnummer"
                    name={"phone"}
                    variant="outlined"
                    sx={{ mt: '15px' }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Meddelande"
                    name={"message"}
                    multiline
                    rows={4}
                    sx={{ mt: '15px' }}
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                  />

                  {errMessage !== '' && (
                    <>
                      <Typography
                        sx={{
                          color:
                            errMessage === 'Meddelandet har skickats.'
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {errMessage}
                      </Typography>
                    </>
                  )}
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    sx={{ mt: '15px' }}
                    onClick={() => {
                      const recipient = broker
                        ? contactRadio === '1'
                          ? 'seller'
                          : 'broker'
                        : 'seller';
                      handleSubmit2(recipient);
                    }}
                  >
                    SKICKA
                  </Button>
                  <Typography sx={{ marginTop: '20px' }}>
                    Ta del av vår{' '}
                    <Link href="/integritetspolicy">integritetspolicy</Link>
                  </Typography>
                </>
              </>
            )}

            <Divider sx={{ mt: '15px' }} />
            {seller.phone && (
              <Fragment>
                <Typography
                  sx={{
                    fontFamily: 'Roboto-Bold',
                    fontSize: '16px',
                    marginTop: '15px',
                    // textTransform: 'uppercase',
                  }}
                >
                  {broker ? (
                    `Behöver du numret till ägaren?`
                  ): (
                    `Behöver du numret till annonsören?`
                  )}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: '15px',
                    width: '100%',
                  }}
                >
                  <TextField
                    id="Ditt nummer"
                    label="Mobilnr eller e-post"
                    variant="outlined"
                    value={mobilePhoneNumberOrEmail}
                    onChange={(e) => setMobilePhoneNumberOrEmail(e.target.value)}
                  />

                  {errDetailsMessage !== '' && (
                    <>
                      <Typography
                        sx={{
                          color:
                            errDetailsMessage === 'Meddelandet har skickats.'
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {errDetailsMessage}
                      </Typography>
                    </>
                  )}
                  <Button
                    variant="contained"
                    sx={{ mt: '15px' }}
                    onClick={() => handleSubmitDetails()}
                  >
                    {broker ? (
                      `SMSa mig ägarens nummer`
                    ) : (
                      `SMSa mig annonsörens nummer`
                    )}
                  </Button>
                </Box>
                <Divider sx={{ mt: '15px' }} />
              </Fragment>
            )}
          </>
        )}
        {broker && (
          <BrokerCard broker={broker} />
        )}
      </Box>
    </Box>
  );
};

type BrokerCardProps = {
  broker: OBI_Broker;
}

function BrokerCard(props: BrokerCardProps) {
  const {broker} = props;
  if (!broker.bitBrokerActive) return null;

  return (
    <Box sx={{ mt: '15px', display: 'flex', justifyContent: 'center' }}>
      <Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: '20px',
            px: '30px',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundImage: `url('https://static2.privatmaklaren.se/brokers/${broker.nBrokerId}.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '250px',
              height: '250px',
              borderRadius: '20px',
            }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                marginTop: '15px',
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              {broker.strBrokerText}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir-Bold',
                fontSize: '23px',
                marginTop: '8px',
                textAlign: 'center',
              }}
            >
              {broker.strBrokerName}
            </Typography>
            <Divider sx={{ mt: '25px', width: '100%' }} />
            <Link href={`tel:${broker.strBrokerTelephone}`} passHref legacyBehavior>
              <Box component="a" sx={{ display: 'flex', mt: '15px' }}>
                <CallIcon />

                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    marginLeft: '5px',
                    textAlign: 'center',
                  }}
                >
                  {broker.strBrokerTelephone}
                </Typography>
              </Box>
            </Link>
            <Link href={`mailto:${broker.strBrokerEmail}`} passHref legacyBehavior>
              <Box component="a" sx={{ display: 'flex', mt: '15px' }}>
                <EmailIcon />

                <Typography
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    marginLeft: '5px',
                    textAlign: 'center',
                  }}
                >
                  {broker.strBrokerEmail}
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default ContactFormXS;
