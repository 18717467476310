import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';

const SliderNextButton = ({ currentSlide, slideCount, ...props }: any) => (
  <Box
    component="button"
    {...props}
    className={'slick-next slick-arrow'}
    aria-hidden="true"
    type="button"
    sx={{
      // border: { xs: '2px solid #383838', md: '3px solid #383838' },
      borderRadius: '50%',
      width: '26px',
      height: '26px',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: '#BDCAD7 !important',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#BDCAD7 !important',
      },
      '&:focus': {
        backgroundColor: '#BDCAD7 !important',
      },
    }}
  >
    <ArrowForwardIosIcon
      sx={{ color: 'white', width: '20px', height: '20px' }}
    />
  </Box>
);

export default SliderNextButton;
