import React from 'react';
import { Divider, Box, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Accordion = ({ data }) => {
  const [selectedMenu, setSelectedMenu] = React.useState([]);

  const refContainers = React.useRef([]);
  React.useMemo(() => {
    refContainers.current = data.map(() => React.createRef<HTMLDivElement>());
  }, [data]);

  const handleClick = (e, index) => {
    e.preventDefault();
    const selIndex = selectedMenu.indexOf(index);
    let selects = selectedMenu;
    if (selIndex > -1) selects.splice(selIndex, 1);
    else selects = [...selects, index];
    setSelectedMenu([...selects]);
  };

  return (
    <>
      {data.map(
        (item, i) =>
          item.content !== '' &&
          item.content !== null && (
            <Box key={i}>
              <Divider
                sx={{
                  backgroundColor: 'black',
                  my: '15px',
                  transform: 'scaleY(0.5)',
                }}
              />

              <Box
                sx={{ display: 'flex', cursor: 'pointer' }}
                onClick={(e) => handleClick(e, i)}
              >
                <Typography
                  component="h3"
                  sx={{
                    my: '5px',
                    fontFamily: 'Avenir',
                    fontSize: { lg: '20px', xs: '16px' },
                    lineHeight: '18px',
                    px: '10px',
                    textTransform: 'uppercase',
                  }}
                >
                  {item.name}
                </Typography>
                <Box
                  sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}
                  className={
                    selectedMenu.indexOf(i) > -1
                      ? 'menu-item-icon-open menu-item-icon'
                      : 'menu-item-icon'
                  }
                >
                  <ArrowForwardIosIcon sx={{ fontSize: 20, color: 'black' }} />
                </Box>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  px: '10px',
                  maxHeight:
                    selectedMenu.indexOf(i) > -1 &&
                    refContainers.current[i].current !== null
                      ? `${refContainers.current[i].current.offsetHeight}px`
                      : '0px',
                }}
                className={'menu-item-content'}
              >
                <Box ref={refContainers.current[i]}>{item.content}</Box>
              </Box>
            </Box>
          )
      )}
    </>
  );
};

export default Accordion;
