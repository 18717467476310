import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ContactButton from '../../../contentful/content-types/ContactButton';

const SellHome = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          my: { xs: '0px', xl: '63px' },
          height: { lg: '394px', xs: '180px' },
          backgroundColor: 'rgba(49, 57, 76, 1)',
        }}
      >
        <Typography
          component="p"
          className="font-Lato-Bold"
          sx={{
            fontSize: { xs: '18px', xl: '36px' },
            color: 'white',
          }}
        >
          Funderar du på att sälja din bostad?
        </Typography>
        <Typography
          component="p"
          className="font-Lato-Regular"
          sx={{
            fontSize: { xs: '12px', lg: '24px' },
            marginTop: { lg: '55px', xs: '9px' },
            marginBottom: { lg: '72px', xs: '22px' },
            color: 'white',
          }}
        >
          Låt oss kontakta dig för en fri värdering.
        </Typography>
        <ContactButton title={'Kontakt'}></ContactButton>
      </Box>
    </>
  );
};
export default SellHome;
