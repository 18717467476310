import React, {Fragment} from 'react';
import HouseInfoXS from './HouseInfoXS';
import HouseInfoLG from './HouseInfoLG';
import AnotherSaleHouseCardSlider from "../../../molecules/AnotherSaleHouseCardSlider";
import SellHome from "../../../molecules/SellHome";
import Container from '@mui/material/Container';

const HouseInfo = ({ data }) => {
  return (
    <Fragment>
      <div className="desktop">
        <HouseInfoLG data={data} />
      </div>
      <div className="mobile">
        <HouseInfoXS data={data} />
      </div>
      <Container maxWidth="lg">
        <SellHome />
        <AnotherSaleHouseCardSlider />
      </Container>
    </Fragment>
  );
};

export default HouseInfo;
