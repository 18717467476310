/**
 * Returns an image url from an image array, or an empty string.
 * @param images
 * @param imageOrder
 * @param width
 * @param height
 * @returns {string|string}
 */
const getObjectImagePath = (images, imageOrder, width=null, height=null) => {
    const regularImages = images.filter(i => i.Type === 0);
    regularImages.sort((a, b) => a.ImageOrder - b.ImageOrder);

    // Trust the key and not the ImageOrder property.
    // Sometimes the ImageOrder from the backend are ordered, but doesn't begin with 1.

    if (!regularImages[imageOrder-1]) {
        return '';
    }

    return regularImages[imageOrder-1].FileURL + ((width && height) ? `?w=${width}&h=${height}`:'');
};

export default getObjectImagePath;