const scrollToAnchor = () => {
    const hash = window.location.hash.substring(1);

    if (hash) {
        try {
            const link = document.querySelector(`#${hash}`);

            if (link) {
                const y = link.offsetTop;
                window.scrollTo(0, y);
            }
        } catch (error) {
            // Fail DOM errors silently
        }
    }
}

export default scrollToAnchor;