import React from 'react';
import ContactFormXS from './ContactFormXS';
import ContactFormLG from './ContactFormLG';
import useWidth from '../../../utils/useWidth';

const ResponsiveComponent = ({
  baseId,
  data,
  message,
  seller,
  tomt,
  status
}) => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' ? (
        <ContactFormLG
          status={status}
          broker={data}
          message={message}
          seller={seller}
          tomt={tomt}
          baseId={baseId}
        />
      ) : (
        <ContactFormXS
          status={status}
          broker={data}
          message={message}
          seller={seller}
          tomt={tomt}
          baseId={baseId}
        />
      )}
    </>
  );
};

const ContactForm = ({ baseId, data, message, seller, tomt, status }) => {
  return (
      <ResponsiveComponent
        status={status}
        baseId={baseId}
        data={data}
        message={message}
        seller={seller}
        tomt={tomt}
      />
  );
};

export default ContactForm;
