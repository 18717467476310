import { Button } from '@mui/material';
import React from 'react';
import Link from 'next/link';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const BlackButton = (props: {
  width?: string;
  height?: string;
  name: string;
  fontSize?: string;
  fontFamily?: string;
  link?: string;
  onClick?: () => void;
}) => {
  if (props.link)
    if (props.link[0] === '#')
      return (
        <AnchorLink
          href={props.link}
          offset={150}
          style={{ textDecoration: 'none' }}
        >
          <Button
            color="info"
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: props.fontSize ?? '12px',
              width: props.width ?? '115px',
              height: props.height ?? '30px',
              borderRadius: '0px',
              backgroundColor: 'rgba(49, 57, 76, 1)',
              color: 'white',
              fontFamily: props.fontFamily ?? 'Roboto',
            }}
          >
            {props.name}
          </Button>
        </AnchorLink>
      );
    else
      return (
        (<Link href={props.link} passHref style={{ textDecoration: 'none' }}>

          <Button
            variant="contained"
            color="info"
            sx={{
              textTransform: 'none',
              fontSize: props.fontSize ?? '12px',
              width: props.width ?? '115px',
              height: props.height ?? '30px',
              borderRadius: '0px',
              backgroundColor: 'rgba(49, 57, 76, 1)',
              color: 'white',
              fontFamily: props.fontFamily ?? 'Roboto',
            }}
          >
            {props.name}
          </Button>

        </Link>)
      );
  return (
    <Button
      color="info"
      variant="contained"
      sx={{
        textTransform: 'none',
        fontSize: props.fontSize ?? '12px',
        width: props.width ?? '115px',
        height: props.height ?? '30px',
        borderRadius: '0px',
        backgroundColor: 'rgba(49, 57, 76, 1)',
        color: 'white',
        fontFamily: props.fontFamily ?? 'Roboto',
      }}
      onClick={props.onClick}
    >
      {props.name}
    </Button>
  );
};

export default BlackButton;
