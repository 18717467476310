import React from 'react';
import { Box, Typography } from '@mui/material';
import HouseCardSliderXS from './HouseCardSliderXS';
import HouseCardSliderLG from './HouseCardSliderLG';
import useWidth from '../../../utils/useWidth';

const ResponsiveComponent = () => {
  const width = useWidth();
  return (
    <>
      {width === 'lg' || width === 'xl' ? (
        <HouseCardSliderLG />
      ) : (
        <HouseCardSliderXS />
      )}
    </>
  );
};

const AnotherSaleHouseCardSlider = () => {
  return (
    <Box
      pt="35px"
      sx={{
        paddingX: { xs: '30px', md: '70px' },
        paddingTop: { xs: '35px', md: '58px' },
        width: '100%',
        paddingBottom: '50px',
      }}
    >
      <Typography
        pl="15px"
        component="p"
        sx={{
          fontSize: { xs: '18px', md: '24px' },
          marginBottom: '20px',
          textTransform: 'uppercase',
          fontFamily: { xs: 'Roboto', lg: 'Avenir-Bold' },
        }}
      >
        Andra Bostäder till salu
      </Typography>

      <Box sx={{ zIndex: '20' }}>
        <ResponsiveComponent />
      </Box>
    </Box>
  );
};

export default AnotherSaleHouseCardSlider;
