import EnergyA from "../../../../../public/images/EnergyA.jpg";
import EnergyB from "../../../../../public/images/EnergyB.jpg";
import EnergyC from "../../../../../public/images/EnergyC.jpg";
import EnergyD from "../../../../../public/images/EnergyD.jpg";
import EnergyE from "../../../../../public/images/EnergyE.jpg";
import EnergyF from "../../../../../public/images/EnergyF.jpg";
import EnergyG from "../../../../../public/images/EnergyG.jpg";
import numberWithSpaces from "../../../../utils/numberWithSpaces";
import {Box, Typography} from "@mui/material";
import React from "react";
import Image from "next/image";
import formatAmount from "../../../../utils/formatAmount";
import Link from "next/link";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";

export const swedishDay = (index) => {
  if (index === 0) return 'Sön';
  if (index === 1) return 'Mån';
  if (index === 2) return 'Tis';
  if (index === 3) return 'Ons';
  if (index === 4) return 'Tors';
  if (index === 5) return 'Fre';
  if (index === 6) return 'Lör';
};

export const swedishMonth = (index) => {
  if (index === 0) return 'Jan';
  if (index === 1) return 'Feb';
  if (index === 2) return 'Mar';
  if (index === 3) return 'Apr';
  if (index === 4) return 'Maj';
  if (index === 5) return 'Jun';
  if (index === 6) return 'Jul';
  if (index === 7) return 'Aug';
  if (index === 8) return 'Sep';
  if (index === 9) return 'Okt';
  if (index === 10) return 'Nov';
  if (index === 11) return 'Dec';
};

export const EnergyItem = (level: string) => {
  if (level === 'A') return EnergyA;
  if (level === 'B') return EnergyB;
  if (level === 'C') return EnergyC;
  if (level === 'D') return EnergyD;
  if (level === 'E') return EnergyE;
  if (level === 'F') return EnergyF;
  if (level === 'G') return EnergyG;
};

type Asd = "asd" | "dsa"
interface dsa {
  asd: Asd
}

export function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export const mapAssociationTypeID = (id) => {
  switch (id) {
    case 1:
      return 'Bostadsrättsförening';
    case 2:
      return 'Bostadsförening';
    case 3:
      return 'Andelsförening';
    case 5:
      return 'Ägarlägenhet';
    default:
      return null;
  }
};

export const mapHouseAssociationTaxationStatusId = (id) => {
  switch (id) {
    case 1:
      return 'Privatbostadsföretag (äkta)';
    case 2:
      return 'Ej privatbostadsföretag (oäkta)';
    default:
      return null;
  }
};

export const mapLotOwnerId = (id) => {
  switch (id) {
    case 1:
      return 'Föreningen äger marken';
    case 2:
      return 'Marken är tomträtt';
    default:
      return null;
  }
};

export const mapPledgingFee = (id) => {
  switch (id) {
    case 1:
      return 'Föreningen tar ut en pantsättningsavgift.';
    case 2:
      return 'Föreningen tar inte ut en pantsättningsavgift.';
    default:
      return null;
  }
};

export const mapTransactionFeeId = (id) => {
  switch (id) {
    case 1:
      return 'Föreningen tar ut en överlåtelseavgift som betalas av köparen.';
    case 2:
      return 'Föreningen tar ut en överlåtelseavgift som betalas av säljaren.';
    case 3:
      return 'Föreningen tar inte ut någon överlåtelseavgift.';
    default:
      return null;
  }
};

export function getObjectHousingAssociation(objectData) {
  return [
    {
      name: 'Namn',
      value: objectData.AssociationName ?? null,
    },
    {
      name: 'Organisationsnummer',
      value: objectData.HouseAssociationOrganizationNumber ?? null,
    },
    {
      name: 'Typ av förening',
      value: mapAssociationTypeID(objectData.AssociationTypeID) ?? null,
    },
    {
      name: 'Skattemässig status',
      value:
        mapHouseAssociationTaxationStatusId(
          objectData.HouseAssociationTaxationStatusId
        ) ?? null,
    },
    {
      name: 'Markägare',
      value: mapLotOwnerId(objectData.LotOwnerId) ?? null,
    },
    {
      name: 'Överlåtelseavgift',
      value: mapTransactionFeeId(objectData.mapTransactionFeeId) ?? null,
    },
    {
      name: 'Pantsättningsavgift',
      value: mapPledgingFee(objectData.PledgingFeedId) ?? null,
    },
    {
      name: 'Om föreningen',
      value: objectData.HousingAssociationComment ?? null,
    },
  ]
}

export function getObjectArea(objectData) {
  return [
    ...(objectData.AreaDescription
      ? [
        {
          name: 'Områdesbeskrivning',
          value: objectData.AreaDescription ?? null,
        },
      ]
      : []),
    ...(objectData.Communications
      ? [
        {
          name: 'Kommunikationer',
          value: objectData.Communications ?? null,
        },
      ]
      : []),
    ...(objectData.Directions
      ? [
        {
          name: 'Vägbeskrivning',
          value: objectData.Directions ?? null,
        },
      ]
      : []),
  ]
}

export function getObjectCostPerYear(objectData) {
  return [
    ...(objectData.JointPropertyUnitCost
      ? [
        {
          name: 'Samfällighet',
          value: numberWithSpaces(objectData.JointPropertyUnitCost) + ' kr',
        },
      ]
      : []),
    ...(objectData.HeatingCost
      ? [
        {
          name: 'Uppvärmning',
          value: !objectData.HeatingCost
            ? null
            : numberWithSpaces(objectData.HeatingCost) + ' kr',
        },
      ]
      : []),
    ...(objectData.ElectricityCost
      ? [
        {
          name: 'El',
          value: !objectData.ElectricityCost
            ? null
            : numberWithSpaces(objectData.ElectricityCost) + ' kr',
        },
      ]
      : []),
    ...(objectData.WaterDrainCost
      ? [
        {
          name: 'VA',
          value: !objectData.WaterDrainCost
            ? null
            : numberWithSpaces(objectData.WaterDrainCost) + ' kr',
        },
      ]
      : []),
    ...(objectData.ChimneySweepCost
      ? [
        {
          name: 'Sotning',
          value: objectData.ChimneySweepCost
            ? numberWithSpaces(objectData.ChimneySweepCost) + ' kr'
            : '',
        },
      ]
      : []),
    ...(objectData.CleaningCost
      ? [
        {
          name: 'Renhållning',
          value: objectData.CleaningCost
            ? numberWithSpaces(objectData.CleaningCost) + ' kr'
            : '',
        },
      ]
      : []),
    ...(objectData.InsuranceCost
      ? [
        {
          name: 'Försäkring',
          value: objectData.InsuranceCost
            ? numberWithSpaces(objectData.InsuranceCost) + ' kr'
            : '',
        },
      ]
      : []),
    ...(objectData.InsuranceComment
      ? [
        {
          name: 'Försäkringsbolag',
          value: objectData.InsuranceComment ?? null,
        },
      ]
      : []),
    ...(objectData.OtherMaintenanceCost
      ? [
        {
          name: 'Övrigt',
          value: formatAmount(objectData.OtherMaintenanceCost)
        },
      ]
      : []),
    ...(objectData.MaintenanceCost
      ? [
        {
          name: 'Total driftskostnad',
          value: objectData.MaintenanceCost
            ? numberWithSpaces(objectData.MaintenanceCost) + ' kr'
            : null,
        },
      ]
      : []),
    ...(objectData.MaintenanceCostNumberOfPeople
      ? [
        {
          name: 'Antal personer i hushållet',
          value: objectData.MaintenanceCostNumberOfPeople ?? null,
        },
      ]
      : []),
    ...(objectData.PropertyTax
      ? [
        {
          name: 'Fastighetsskatt/-avgift',
          value: objectData.PropertyTax
            ? numberWithSpaces(objectData.PropertyTax) + ' kr'
            : null,
        },
      ]
      : []),
    {
      name: 'Tomträttsavgäld',
      value: objectData.LandLeaseholdFee ? `${numberWithSpaces(objectData.LandLeaseholdFee)} kr/år` : null,
    },
    ...(objectData.GroundLeaseFee
      ? [
        {
          name: 'Arrendeavgift ',
          value: numberWithSpaces(objectData.GroundLeaseFee) + ' kr' ?? null,
        },
      ]
      : []),
    ...(objectData.MaintenanceCostComment
      ? [
        {
          name: 'Kommentar till driftskostnader',
          value: objectData.MaintenanceCostComment ?? null,
        },
      ]
      : []),
  ];
}

export function getObjectDocuments(objectData) {
  return [
    ...objectData.files.map((f) => {
      return {
        type: 1,
        name: f.FileText || f.OriginalFileName,
        url: f.FileURL,
      };
    }),
    ...objectData.links.map((f) => {
      return {
        type: 2,
        name: f.sTitle,
        url: f.sURL,
      };
    }),
  ];
}

export function getImportantInformation(objectData) {
  return !objectData.broker
    ? [
      {
        title: '',
        content: (
          <Typography
            component="p"
        sx={{ fontFamily: 'Lato', fontSize: '16px' }}
>
            Detta objekt säljs utan inblandning av fastighetsmäklare. Försäljningen hanteras av en advokat ansvarig för en offentlig auktion, som har valt att använda Privatmäklarens annonstjänst. Samtliga uppgifter i annonsen kommer direkt från advokaten/annonsören, och Privatmäklaren Skandinavien ansvarar inte för riktigheten i de uppgifter som presenteras i beskrivningen.
    </Typography>
),
},
]
: [
    ...(objectData.TypeID === 1 && objectData.HouseTypeId === 11
      ? [
        {
          title: '',
          content: (
            <Typography
              component="p"
          sx={{ fontFamily: 'Lato', fontSize: '16px' }}
>
  Vi förmedlar tomten tillsammans med dess ägare som själv tar
  hand om visningen. Vi på Privatmäklaren finns till hands via
  telefon och mejl för att svara på spekulanternas frågor.
    Tryggheten och expertisen är kvar men arvodet är betydligt
  lägre. Läs gärna mer om oss på{' '}
  <a rel="noreferrer" target="_blank" href="http://WWW.PM.SE">
    WWW.PM.SE
    </a>
    </Typography>
),
},
]
: []),
...(!(objectData.TypeID === 1 && objectData.HouseTypeId === 11)
    ? [
      {
        title: '',
        content: (
          <Typography
            component="p"
        sx={{ fontFamily: 'Lato', fontSize: '16px' }}
>
  Vi förmedlar bostaden tillsammans med bostadens ägare som
  själv tar hand om visningen. Vi på Privatmäklaren ser till
  att det finns visningsmaterial till visningen och vi finns
  till hands via telefon och mejl för att svara på
  spekulanternas frågor. Tryggheten och expertisen är kvar men
  arvodet är betydligt lägre. Läs gärna mer om oss och våra
  nöjda kunder på{' '}
  <a rel="noreferrer" target="_blank" href="http://WWW.PM.SE">
    WWW.PM.SE
    </a>
    </Typography>
),
},
]
: []),
  {
    title: 'Budgivning och kontrakt',
      content: (
    <Typography
      component="p"
    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
  >
    {`Högsta budet (om något bud inkommit) redovisas på denna sida om inte
          säljaren valt att hålla budet dolt. Nya bud redovisas löpande till
          säljaren och övriga budgivare (om inte säljaren givit mäklaren
          instruktioner om annat). Det är alltid säljaren som bestämmer till
          vem, när och hur samt till vilket pris han eller hon vill sälja.
          Samtliga bud och budgivarnas namn redovisas till den slutliga köparen.
          I det fall då det kommer in ett nytt bud efter det att budgivningen är
          avslutad men köpeavtalen ännu inte är påskrivna, är fastighetsmäklaren
          skyldig att framföra budet till säljaren.`}
    </Typography>
  ),
  },
  {
    title: 'Undersökningsplikt',
      content:
    objectData.TypeID === 1 ? (
        <Typography
          component="p"
      sx={{ fontFamily: 'Lato', fontSize: '16px' }}
  >
    Enligt lag har köparen en omfattande undersökningsplikt och
    uppmanas att undersöka objektet ordentligt.{' '}
    <a
      rel="noreferrer"
    target="_blank"
    href="https://static2.privatmaklaren.se/files/kopare_och_saljares_ansvar.pdf"
      >
      KÖPARE OCH SÄLJARES ANSVAR
  </a>
  </Typography>
  ) : (
    <Typography
      component="p"
    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
  >
    Utgångsläget är att du köper bostadsrätten i befintligt skick
    och uppmanas att undersöka objektet ordentligt.{' '}
    <a
      rel="noreferrer"
    target="_blank"
    href="https://static2.privatmaklaren.se/files/unders%C3%B6kningsplikt_felansvar_br.pdf"
      >
      KÖPARE OCH SÄLJARES ANSVAR
  </a>
  </Typography>
  ),
  },
...(!(objectData.TypeID === 1 && objectData.HouseTypeId === 11)
    ? [
      {
        title: 'Boarea/biarea',
        content: (
          <Typography
            component="p"
        sx={{ fontFamily: 'Lato', fontSize: '16px' }}
>
  Då det genom åren funnits olika mätnormer för mätning av
  boyta kan boarean avvika från uppmätning med gällande
  mätnorm. Därmed finns inga garantier för att boytan stämmer
  med dagens norm. Köpare som känner sig osäker på boytan bör
  därför kontrollera boarean före köp.
  </Typography>
),
},
]
: []),
...(!(objectData.TypeID === 1 && objectData.HouseTypeId === 11)
    ? [
      {
        title: 'Boendekostnadskalkyl',
        content: (
          <Typography
            component="p"
        sx={{ fontFamily: 'Lato', fontSize: '16px' }}
>
  Ansvarig mäklare erbjuder dig som skall köpa bostad en
  skriftlig beräkning över dina personliga boendekostnader. Om
  du önskar boendekostnadskalkyl - meddela mäklaren.
  </Typography>
),
},
]
: []),
  {
    title: 'Ersättning från annan verksamhet',
      content: (
    <Typography
      component="p"
    sx={{ fontFamily: 'Lato', fontSize: '16px' }}
  >
    Mäklaren har rätt att få ersättning från annan verksamhet än
    förmedling av bostäder. Denna ersättning får inte vara mer än
    obetydlig. Våra mäklare erhåller endast en administrativ
    ersättning från Hemnet som uppgår till 40% av Hemnets fakturerade
    belopp per förmedling{' '}
    <a
      rel="noreferrer"
    target="_blank"
    href="https://www.hemnet.se/annonsera-bostad#priskalkylatorn"
      >
      ENL. HEMNETS PRISKALKYLATOR
  </a>
  </Typography>
  ),
  },
];
}

export function getObjectBuilding(houseTypes, objectData) {
  return [
    {
      name: 'Boendeform',
      value:
        houseTypes.find((f) => f.HouseTypeID === objectData.HouseTypeId).Type ??
        null,
    },
    {
      name: 'Byggnadstyp',
      value: objectData.TypeOfConstruction ?? null,
    },
    {
      name: 'Byggnadsår',
      value: objectData.ConstructionYear ?? null,
    },
    {
      name: 'Fönster',
      value: objectData.WindowComment ?? null,
    },
    {
      name: 'Uppvärmning',
      value: objectData.HeatingComment ?? null,
    },
    {
      name: 'Ventilation',
      value: objectData.VentilationComment ?? null,
    },
    {
      name: 'Internet',
      value: objectData.Internet ?? null,
    },
    {
      name: 'Fasad',
      value: objectData.FasadeComment ?? null,
    },
    {
      name: 'Utv. plåtarbete',
      value: objectData.MetalworkingComment ?? null,
    },
    {
      name: 'Tak',
      value: objectData.RoofComment ?? null,
    },
    {
      name: 'Stomme',
      value: objectData.FrameworkComment ?? null,
    },
    {
      name: 'Bjälklag',
      value: objectData.BeamsComment ?? null,
    },
    {
      name: 'Grundmur',
      value: objectData.FoundationwallComment ?? null,
    },
    {
      name: 'Grundläggning',
      value: objectData.GroundfloorComment ?? null,
    },
    {
      name: 'Övrigt om huvudbyggnaden',
      value: objectData.BuildingComment ?? null,
    },
    {
      name: 'Övriga byggnader',
      value: objectData.OtherBuildingsComment ?? null,
    },
    ...(objectData.AreaLant !== null &&
    objectData.BiAreaLant !== null &&
    objectData.Area !== null &&
    objectData.BiArea !== null &&
    objectData.isBroker === 1 &&
    (objectData.AreaLant > 0.0 || objectData.BiAreaLant > 0.0) &&
    (objectData.Area !== objectData.AreaLant ||
      objectData.BiArea !== objectData.BiAreaLant)
      ? [
        {
          name: 'Boarea och biarea',
          value: (
            <>
              {`Säljaren har angett boarea ${objectData.Area} kvm och biarea $
                    {objectData.BiArea} m`}
              <sup>2</sup>
              {`, enligt taxeringsinformationen är boarean $
                    {objectData.AreaLant} kvm och biarean ${objectData.BiAreaLant} m`}
              <sup>2</sup>
              {'.'}
            </>
          ),
        },
      ]
      : [
        {
          name: 'Boarea',
          value:
            objectData.TypeID === 1 && objectData.Area ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ position: 'relative', mr: 2 }}>
                  {`${objectData.Area} m `}
                  <sup
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-10px',
                    }}
                  >
                    2
                  </sup>
                </Box>
              </Box>
            ) : (
              ''
            ),
        },
        {
          name: 'Biarea',
          value:
            objectData.TypeID === 1 && objectData.BiArea ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ position: 'relative', mr: 2 }}>
                  {`${objectData.BiArea} m `}
                  <sup
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-10px',
                    }}
                  >
                    2
                  </sup>
                </Box>
              </Box>
            ) : (
              ''
            ),
        },
      ]),
    ...objectData.TypeID === 1 ? [
      {
        name: 'Kommentar till boarean',
        value: objectData.LivingAreaComment ?? null,
      }]:
    [],
    {
      name: 'Specifik energianvändning (tidigare energiprestanda)',
      value: objectData.EnergyPerformance ? `${objectData.EnergyPerformance} kWh/m2 och år` : null,
    },
    {
      name: 'Energiprestanda, primärenergital',
      value: objectData.EnergyPrimaryPerformance ? `${objectData.EnergyPrimaryPerformance} kWh/m2 och år` : null,
    },
    {
      name: 'Energiklass',
      value:
        objectData.EnergyClassification !== ' ' &&
        objectData.EnergyClassification !== null ? (
          <Image
            src={EnergyItem(objectData.EnergyClassification)}
            width={30}
            height={30}
            alt="Energiklass"
          />
        ) : null,
    },
  ]
}

export function getObjectRealEstate(objectData, coaAssociationTypes, houseAssociationTypes) {
  return [
    {
      name: 'Fastighetsbeteckning ',
      value: objectData.PropertyDesignation ?? null,
    },
    {
      name: 'Typkod ',
      value: objectData.AssessCodeID ?? null,
    },
    {
      name: 'Taxeringsvärde byggnad',
      value: objectData.AssessedValue
        ? numberWithSpaces(objectData.AssessedValue) + ' kr'
        : '',
    },
    {
      name: 'Taxeringsvärde mark',
      value: objectData.AssessedValueLand
        ? numberWithSpaces(objectData.AssessedValueLand) + ' kr'
        : '',
    },
    {
      name: 'Taxeringsvärde totalt',
      value:
        objectData.Assessedvalue || objectData.AssessedValueLand
          ? numberWithSpaces(
          (objectData.AssessedValue ?? 0) +
          (objectData.AssessedValueLand ?? 0)
        ) + ' kr'
          : '',
    },
    {
      name: 'Tomtarea ',
      value: objectData.LotArea ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ position: 'relative', mr: 2 }}>
            {`${numberWithSpaces(objectData.LotArea)} m `}
            <sup
              style={{
                position: 'absolute',
                top: '-5px',
                right: '-10px',
              }}
            >
              2
            </sup>
          </Box>
        </Box>
      ) : (
        ''
      ),
    },
    {
      name: 'Upplåtelseform ',
      value:
        objectData.AssociationTypeID === null
          ? null
          : objectData.TypeID === 0
            ? coaAssociationTypes.find(
            (f) => f.AssociationTypeID === objectData.AssociationTypeID
          ).Type ?? null
            : houseAssociationTypes.find(
            (f) => f.TypeId === objectData.AssociationTypeID
          ).TypeName ?? null,
    },
    {
      name: 'Arrendeavgift',
      value: formatAmount(objectData.GroundLeaseFee) ?? null,
    },
    {
      name: 'Vatten ',
      value: objectData.WaterType ?? null,
    },
    {
      name: 'Avlopp ',
      value: objectData.DrainType ?? null,
    },
    {
      name: 'Servitut etc.',
      value: objectData.EasementComment ?? null,
    },
    {
      name: 'Totalt pantbrevsbelopp',
      value: objectData.MortgageBondComment
        ? numberWithSpaces(objectData.MortgageBondComment) + ' kr'
        : null,
    },
    {
      name: 'Övrigt',
      value: objectData.LotComment ?? null,
    },
  ]
}

export function getObjectSummary(houseTypes, objectData, coaAssociationTypes, houseAssociationTypes) {
  return [
    {
      name: 'Boendeform',
      value:
        houseTypes.find((f) => f.HouseTypeID === objectData.HouseTypeId).Type ??
        null,
    },
    {
      name: 'Upplåtelseform ',
      value:
        objectData.AssociationTypeID === null
          ? null
          : objectData.TypeID === 0
            ? coaAssociationTypes.find(
            (f) => f.AssociationTypeID === objectData.AssociationTypeID
          ).Type ?? null
            : houseAssociationTypes.find(
            (f) => f.TypeId === objectData.AssociationTypeID
          ).TypeName ?? null,
    },
    {
      name: 'Rum',
      value: objectData.NumberOfRooms ?? null,
    },
    {
      name: 'Lägenhetsnummer',
      value: objectData.ApartmentNumber ?? null,
    },
    {
      name: 'Lägenhetsnummer skatteverket',
      value: objectData.GovernmentApartmentNumber ?? null,
    },
    {
      name: 'Andel i föreningen',
      value: !objectData.Share ? null : objectData.Share + '%',
    },
    {
      name: 'Andel av månadsavgift',
      value: !objectData.ShareFee ? null : objectData.ShareFee + '%',
    },
    {
      name: 'Boarea',
      value: !objectData.Area ? (
        ''
      ) : (
        <>
          {`${objectData.Area} m`}
          <sup>2</sup>
        </>
      ),
    },
    {
      name: 'Biarea',
      value: !objectData.BiArea ? (
        ''
      ) : (
        <>
          {`${objectData.BiArea} m`}
          <sup>2</sup>
        </>
      ),
    },
    {
      name: 'Tomtarea',
      value: !objectData.LotArea ? (
        ''
      ) : (
        <>
          {`${numberWithSpaces(objectData.LotArea)} m`}
          <sup>2</sup>
        </>
      ),
    },
    {
      name: 'Kommentar till boarean',
      value: objectData.LivingAreaComment ?? null,
    },
    {
      name: 'Pris',
      value: !objectData.Price
        ? null
        : numberWithSpaces(objectData.Price) + ' kr',
    },
    {
      name: 'Avgift',
      value: !!objectData.MonthlyFee
        ? numberWithSpaces(objectData.MonthlyFee) + ' kr'
        : null,
    },
    {
      name: 'Bostadsrättens indirekta nettoskuld',
      value: !!objectData.ShareOfHousingAssociationNetDebt
        ? numberWithSpaces(objectData.ShareOfHousingAssociationNetDebt) + ' kr'
        : null,
    },
    {
      name: '',
      value: objectData.ShareOfHousingAssociationNetDebtDescription ?
        objectData.ShareOfHousingAssociationNetDebtDescription : null
    },
    {
      name: 'Våning',
      value: objectData.FloorNumber ? `${objectData.FloorNumber} av ${objectData.FloorTotal}` : null,
    },
    ...(objectData.TypeID === 0
      ? [
        {
          name: 'Hiss finns',
          value: objectData.Elevator ?? null === 1 ? 'Ja' : 'Nej',
        },
      ]
      : []),
    {
      name: 'Förening',
      value: objectData.AssociationName ?? null,
    },
    {
      name: 'Byggår',
      value: objectData.ConstructionYear ?? null,
    },
    {
      name: 'Allmänt om bostaden',
      value: !objectData.Other ? null : (
        <>
          <p style={{ fontFamily: 'inherit', margin: 0, padding: 0, whiteSpace: 'pre-wrap' }}>
            {objectData.Other.replaceAll('\n', String.fromCharCode(13, 10))}
          </p>
        </>
      ),
    },
    {
      name: 'Tillträde',
      value: objectData.AdmissionType ?? null,
    },
  ]
}

export function getAccordionData(objectSummary, objectData, objectRooms, objectBuilding, objectRealEstate, objectHousingAssociation, objectArea, objectCostPerYear, objectDocuments, importantInformation) {
  return [
    {
      name: 'Sammanfattning',
      content:
        objectSummary.length === 0 ? (
          ''
        ) : (
          <Box mt={0}>
            {objectSummary.map(
              (item, i) =>
                item.value !== null &&
                item.value !== '' &&
                item.value !== ' ' && (
                  <Box key={i}>
                    <Typography
                      component="b"
                      sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ fontFamily: 'Lato', fontSize: '16px',
                        display: 'block' }}
                    >
                      {item.value}
                    </Typography>
                    {item.name === 'Avgift' &&
                      (objectData.IncHeating ||
                        objectData.IncCableTv ||
                        objectData.IncVA ||
                        objectData.IncAtticStorage ||
                        objectData.IncBroadBand ||
                        objectData.IncElectricity ||
                        objectData.IncGarageSpace ||
                        objectData.ParkingSpace) && (
                        <Typography
                          component="span"
                          sx={{ fontFamily: 'Lato', fontSize: '16px',
                            display: 'block' }}
                        >
                          {'inkl ' +
                            [
                              { field: objectData.IncHeating, value: 'värme' },
                              {
                                field: objectData.IncCableTv,
                                value: 'kabel-TV',
                              },
                              { field: objectData.IncVA, value: 'VA' },
                              {
                                field: objectData.IncAtticStorage,
                                value: 'förråd',
                              },
                              {
                                field: objectData.IncBroadBand,
                                value: 'bredband',
                              },
                              { field: objectData.IncElectricity, value: 'el' },
                              {
                                field: objectData.IncGarageSpace,
                                value: 'garageplats',
                              },
                              { field: objectData.ParkingSpace, value: 'parkering' },
                            ]
                              .filter((i) => i.field === true)
                              .map((i) => i.value)
                              .join(', ')}
                        </Typography>
                      )}
                    {item.name === 'Avgift' && objectData.MonthlyFeeComment && (
                      <Typography
                        component="span"
                        sx={{ fontFamily: 'Lato', fontSize: '16px',
                          display: 'block' }}
                      >
                        {objectData.MonthlyFeeComment}
                      </Typography>
                    )}
                  </Box>
                )
            )}
          </Box>
        ),
    },
    {
      name: 'Rum',
      content:
        objectRooms.length === 0 ? (
          ''
        ) : (
          <Box mt={0}>
            {objectRooms.map((item, i) => (
              <Box key={i}>
                <Typography
                  component="b"
                  sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                >
                  {item.name}
                </Typography>
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    whiteSpace: 'pre-wrap',
                    display: 'block'
                  }}
                >
                  {item.desc}
                </Typography>
              </Box>
            ))}
          </Box>
        ),
    },
    {
      name: 'Byggnad',
      content:
        (objectData.TypeID === 1 && objectData.HouseTypeId === 11) ||
        objectBuilding.length === 0 ? (
          ''
        ) : (
          <Box mt={0}>
            {objectBuilding.map(
              (item, i) =>
                item.value !== null &&
                item.value !== '' &&
                item.value !== ' ' && (
                  <Box key={i}>
                    <Typography
                      component="b"
                      sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        whiteSpace: 'pre-wrap',
                        display: 'block'
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                )
            )}
          </Box>
        ),
    },
    {
      name: objectData.TypeID === 1 ? 'Fastighet' : 'Förening',
      content:
        (objectData.TypeID === 1 && objectRealEstate.length === 0) ||
        (objectData.TypeID !== 1 && objectHousingAssociation.length === 0) ? (
          ''
        ) : (
          <Box mt={0}>
            {objectData.TypeID === 1 ? (
              <>
                {objectRealEstate.map(
                  (item, i) =>
                    item.value !== null &&
                    item.value !== '' &&
                    item.value !== ' ' && (
                      <Box key={i}>
                        <Typography
                          component="b"
                          sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            whiteSpace: 'pre-wrap',
                            display: 'block'
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Box>
                    )
                )}
              </>
            ) : (
              <>
                {objectHousingAssociation.map(
                  (item, i) =>
                    item.value !== null &&
                    item.value !== '' &&
                    item.value !== ' ' && (
                      <Box key={i}>
                        <Typography
                          component="b"
                          sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            whiteSpace: 'pre-wrap',
                            display: 'block'
                          }}
                        >
                          {item.value}
                        </Typography>
                      </Box>
                    )
                )}
              </>
            )}
          </Box>
        ),
    },
    {
      name: 'Område',
      content:
        objectArea.map((i) => i.value).filter((i) => i !== null).length ===
        0 ? (
          'Områdesbeskrivning saknas.'
        ) : (
          <Box mt={0}>
            {objectArea.map(
              (item, i) =>
                item.value !== null &&
                item.value !== '' &&
                item.value !== ' ' && (
                  <Box key={i}>
                    <Typography
                      component="b"
                      sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        whiteSpace: 'pre-wrap',
                        display: 'block'
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                )
            )}
          </Box>
        ),
    },
    {
      name: 'Kostnader per år',
      content:
        objectCostPerYear.length === 0 ? (
          'Uppgifter om driftskostnader saknas'
        ) : (
          <Box mt={0}>
            {objectCostPerYear.map(
              (item, i) =>
                item.value !== null &&
                item.value !== '' &&
                item.value !== ' ' && (
                  <Box key={i}>
                    <Typography
                      component="b"
                      sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        whiteSpace: 'pre-wrap', display: 'block'
                      }}
                    >
                      {item.value}
                    </Typography>
                  </Box>
                )
            )}
            <Typography
              component="span"
              sx={{ fontFamily: 'Lato', fontSize: '16px', display: 'block' }}
            >
              Observera att driftskostnaderna är personliga och kan komma att
              skilja sig beroende på användningssätt. Denna driftskostnad
              baseras på säljarens uppgift.
            </Typography>
          </Box>
        ),
    },
    {
      name: 'Dokument och länkar',
      content: (
        <Box mt={0}>
          {objectDocuments.length === 0 && (
            <>
              <Typography
                sx={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: '14px',
                  lineHeight: '18px',
                  textOverflow: 'ellipsis',
                }}
              >
                Inga dokument hittades
              </Typography>
            </>
          )}
          {objectDocuments.map((item, i) => (
            (<Link
              href={item.url}
              as={item.url}
              key={i}
              style={{ color: 'black' }}
              target="_blank">

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {item.type === 1 && <PictureAsPdfIcon fontSize="large" />}
                {item.type === 2 && <LaunchRoundedIcon fontSize="large" />}
                <Typography
                  sx={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: '14px',
                    lineHeight: '18px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {item.name}
                </Typography>
              </Box>

            </Link>)
          ))}
        </Box>
      ),
    },
    {
      name: 'Viktig information',
      content: (
        <Box mt={0}>
          {importantInformation.map((item, i) => (
            <Box key={i}>
              <Typography
                component="b"
                sx={{ fontFamily: 'Lato-Bold', fontSize: '18px' }}
              >
                {item.title}
              </Typography>

              {item.content}
            </Box>
          ))}
        </Box>
      ),
    },
  ];
}
